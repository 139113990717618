"use client";

import { Link } from "../base/link";
import { CalendarIcon } from "../icons/icons";
import { paths } from "@/utils/paths-utils";
import { useContext } from "react";
import { MenuContext } from "@/contexts/menu-context";
import { lockBodyWhenModalIsOpen } from "@/utils/modal-utils";

export const MenuBookingCalendarButton = () => {
  const { setMobileMenuIsOpen } = useContext(MenuContext);

  return (
    <Link
      href={paths.bookingLandingPage()}
      onClick={() => {
        lockBodyWhenModalIsOpen(false);
        setMobileMenuIsOpen(false);
      }}
    >
      <CalendarIcon name="Book time" />
    </Link>
  );
};
