"use client";

import { useContext } from "react";
import { useSession } from "next-auth/react";

import { Link } from "../base/link";
import { UserIconOutline, UserIconSolid } from "../icons/icons";
import { MenuContext } from "@/contexts/menu-context";
import { usePathname } from "next/navigation";
import { ROOT_URL } from "@/utils/paths-utils";
import { lockBodyWhenModalIsOpen } from "@/utils/modal-utils";

export const MenuUserButton = () => {
  const { data: session } = useSession();
  const { setMobileMenuIsOpen } = useContext(MenuContext);
  const path = usePathname();
  const currentUrl = ROOT_URL + path;

  if (session) {
    return (
      <Link
        href="/min-side"
        onClick={() => {
          lockBodyWhenModalIsOpen(false);
          setMobileMenuIsOpen(false);
        }}
      >
        {/* TODO: vis alert hvis det er noe */}
        <UserIconSolid name="Min side" />
      </Link>
    );
  }

  return (
    <Link
      href={`/logg-inn?callbackUrl=${currentUrl}`}
      onClick={() => {
        lockBodyWhenModalIsOpen(false);
        setMobileMenuIsOpen(false);
      }}
    >
      <UserIconOutline name="Logg inn" />
    </Link>
  );
};
