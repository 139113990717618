"use client";

import { useContext } from "react";

import { Link } from "../base/link";
import { MenuContext } from "@/contexts/menu-context";
import { lockBodyWhenModalIsOpen } from "@/utils/modal-utils";
import { SearchIcon } from "../icons/icons";
import { SearchContext } from "@/contexts/search-context";

export const SearchButton = () => {
  const { setMobileMenuIsOpen } = useContext(MenuContext);
  const { setSearchModalActive } = useContext(SearchContext);

  return (
    <Link
      onClick={() => {
        lockBodyWhenModalIsOpen(true);
        setSearchModalActive(true);
        setMobileMenuIsOpen(false);
      }}
    >
      <SearchIcon name="Søk" />
    </Link>
  );
};
