"use client";
import { useContext, useState } from "react";
import { signOut } from "next-auth/react";
import { MenuContext } from "@/contexts/menu-context";
import { useOutsideClick } from "@/hooks/use-outside-click";
import { IconMenu } from "@/components/icons/icons";
import { MainMenuBlockUnionPartsFragment } from "@/generated/client.generated";
import { MenuBlock } from "./menu-block";
import { VerticalContainer } from "../layout/vertical-container";
import { paths } from "@/utils/paths-utils";
import { LinkButton } from "../ui/link-button";
import { VerticalSpacer } from "../layout/vertical-spacer";
import { HorizontalContainer } from "../layout/horizontal-container";
import { HorizontalSpacer } from "../layout/horizontal-spacer";
import { UserContext } from "@/contexts/user-context";
import { MenuUserButton } from "./menu-user-button";
import { MenuItem } from "./menu-item";
import { MenuBookingCalendarButton } from "./menu-booking-calendar-button";
import { lockBodyWhenModalIsOpen } from "@/utils/modal-utils";
import { Logo } from "@/components/ui/logo";
import { SearchButton } from "./search-button";

interface MenuProps {
  menuBlocks: Array<MainMenuBlockUnionPartsFragment>;
}

export const Menu = ({ menuBlocks }: MenuProps) => {
  const { user } = useContext(UserContext);
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState<boolean>(false);

  const [currentOpenMenuSection, setCurrentOpenMenuSection] = useState<string | undefined>(undefined);

  const refDesktop = useOutsideClick(() => {
    if (currentOpenMenuSection) {
      setCurrentOpenMenuSection(undefined);
    }
  });

  const blocks = menuBlocks.map((block, index) => <MenuBlock block={block} key={index} />);

  return (
    <div className="menu-content">
      <MenuContext.Provider
        value={{
          currentOpenMenuSection,
          mobileMenuIsOpen,
          setCurrentOpenMenuSection,
          setMobileMenuIsOpen
        }}
      >
        <Logo
          href={paths.hjem}
          onClick={() => {
            lockBodyWhenModalIsOpen(false);
            setMobileMenuIsOpen(false);
          }}
        />

        <nav>
          <HorizontalContainer horizontalPlacement="space" verticalPlacement="center" width="full">
            <div className="menu-desktop" ref={refDesktop}>
              <HorizontalContainer verticalPlacement="top" noWrap>
                {blocks}
                {blocks && <MenuItem text="Kundeservice" url={paths.kundeserviceLandingPage} />}
              </HorizontalContainer>
            </div>

            <HorizontalContainer>
              <div className="menu">
                <MenuBookingCalendarButton />
              </div>

              <SearchButton />
              <MenuUserButton />

              <div className="menu">
                <button
                  aria-label="Mobilmeny"
                  onClick={() => {
                    lockBodyWhenModalIsOpen(!mobileMenuIsOpen);
                    setMobileMenuIsOpen(!mobileMenuIsOpen);
                  }}
                  type="button"
                >
                  <IconMenu isActive={mobileMenuIsOpen} />
                </button>

                {mobileMenuIsOpen && (
                  <div className="menu__menu">
                    <div className="menu__menu__content">
                      <VerticalContainer horizontalPlacement="center">{blocks}</VerticalContainer>
                      <HorizontalSpacer size="large" />
                      <HorizontalSpacer size="large" />

                      <HorizontalContainer horizontalPlacement="center" verticalPlacement="center" width="full">
                        <LinkButton
                          buttonType="neutral"
                          href={paths.kundeserviceLandingPage}
                          onClick={() => {
                            setMobileMenuIsOpen(false);
                            lockBodyWhenModalIsOpen(false);
                          }}
                        >
                          Kundeservice
                        </LinkButton>
                        <VerticalSpacer />

                        {user === undefined ? (
                          <LinkButton
                            buttonType="primary"
                            href={paths.bliMedlem}
                            onClick={() => {
                              setMobileMenuIsOpen(false);
                              lockBodyWhenModalIsOpen(false);
                            }}
                          >
                            Bli 3T-medlem
                          </LinkButton>
                        ) : (
                          <LinkButton
                            buttonType="primary"
                            href=""
                            onClick={async () => {
                              await signOut({ callbackUrl: "/", redirect: true });
                            }}
                          >
                            Logg ut
                          </LinkButton>
                        )}
                      </HorizontalContainer>
                    </div>
                  </div>
                )}
              </div>

              <div className="menu-desktop">
                <HorizontalContainer noWrap>
                  <VerticalSpacer size={"xx-small"} />
                  {user && user.subscriptions?.length > 0 ? (
                    <LinkButton buttonType="primary" href={paths.vervEnVenn}>
                      Verv en venn
                    </LinkButton>
                  ) : (
                    <LinkButton buttonType="primary" href={paths.bliMedlem}>
                      Bli medlem
                    </LinkButton>
                  )}
                </HorizontalContainer>
              </div>
            </HorizontalContainer>
          </HorizontalContainer>
        </nav>
      </MenuContext.Provider>
    </div>
  );
};
